import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const urlbackend = process.env.REACT_APP_BACKEND_URL;
  

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(urlbackend + '/session/current_user', {
          withCredentials: true
        })
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const logout = async () => {
    try {
      await axios.post(urlbackend+'/logout'); // Asegúrate de que la URL sea correcta
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  
  const updateUser = (updatedUserInfo) => {
    setUser(updatedUserInfo);
  };
  return (
    <UserContext.Provider value={{ user, setUser, isLoading, logout, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
