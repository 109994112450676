import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import eyeOpenIcon from '../images/components/eye-open.svg';
import eyeClosedIcon from '../images/components/eye-closed.svg';
import '../styles/resetpassword.css'

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const urlbackendNode = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    oneNumber: false,
    oneUpper: false,
    oneSpecial: false
  });

  useEffect(() => {
    setPasswordValidation({
      minLength: password.length >= 8,
      oneNumber: /[0-9]/.test(password),
      oneUpper: /[A-Z]/.test(password),
      oneSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    });
  }, [password]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);
    setSuccessMessage('');
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post(urlbackendNode  + `/reset-password/${token}`, { password });
      setSuccessMessage("Password saved successfully...");
    } catch (error) {  
      setIsLoading(false);   
      setErrorMessage(error.response.data);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <section id="reset-password-container">
      <div className="reset-password">
        {!successMessage ? (
          <>
          <h2>Reset Password</h2>
          <form onSubmit={handleSubmit}>
          <div className="password-input-container1">
              <label>New Password:</label>
              <input 
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                required
              />
              <button type="button" onClick={togglePasswordVisibility} className="toggle">
                <img src={showPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
              </button>
            </div>
            <ul className="password-criteria">
                  <li style={{ color: passwordValidation.minLength ? '#11d03a' : '#b6b6b6' }}>At least 8 characters</li>
                  <li style={{ color: passwordValidation.oneNumber ? '#11d03a' : '#b6b6b6' }}>At least one number</li>
                  <li style={{ color: passwordValidation.oneUpper ? '#11d03a' : '#b6b6b6' }}>At least one uppercase letter</li>
                  <li style={{ color: passwordValidation.oneSpecial ? '#11d03a' : '#b6b6b6' }}>At least one special character</li>
            </ul>

            <div className="password-input-container1">
              <label>Confirm Password:</label>
              <input 
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <button type="button" onClick={toggleConfirmPasswordVisibility} className="toggle">
                <img src={showConfirmPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
              </button>
            </div>
            {errorMessage && <p style= {{ color: '#ff4a4a', marginTop: '0px', fontSize: '0.8rem', textAlign: 'start', margin: '0px'}}>{errorMessage}</p>}
            <button type="submit" id="submit-button">{isLoading ? <div className="loader"></div> : 'Reset Password'}</button>
            
          </form>
        </>
        ) : (
          <p className="success-message" style={{ color: '#00c853', fontSize: '1.1rem', textAlign: 'center', margin: '40px 0px', lineHeight: '1.8rem' }}>
            {successMessage}
          </p>
        )}     
      </div>

    </section>
    
  );
};

export default ResetPassword;
