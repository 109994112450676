import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/test.css'


function Test () {
    return (
        <div className='Test'>
            <div className='spinner-container'>
                {Array.from({ length: 20 }, (_, i) => (
                    <span style={{ '--i': i + 1 }} key={i}></span>
                ))}
                <p>Loading</p>

            </div>

        </div>
    )
}
export default Test;
