import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import $ from 'jquery';
import '../styles/settings.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Upload from '../images/components/upload.png';
import eyeOpenIcon from '../images/components/eye-open.svg';
import eyeClosedIcon from '../images/components/eye-closed.svg';
import ChangePhoto from './popups/ChangePhoto';
import DeleteAccountPopup from './popups/DeleteAccount';
import axios from 'axios';

function Settings() {
    const { user, logout,  setUser } = useUser();
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [personalInfoMsg, setPersonalInfoMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const urlbackend = process.env.REACT_APP_BACKEND_URL;

    const [isLoading, setIsLoading] = useState(false);
    const [LoadingPersonalInfo, setLoadingPersonalInfo] = useState(false);
    const [LoadingPassword, setLoadingPassword] = useState(false);

    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);

    const [passwordValidation, setPasswordValidation] = useState({
        minLength: false,
        oneNumber: false,
        oneUpper: false,
        oneSpecial: false
      });

    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Efecto para los requerimientos del cambio de contraseña
    useEffect(() => {
        setPasswordValidation({
        minLength: newPassword.length >= 8,
        oneNumber: /[0-9]/.test(newPassword),
        oneUpper: /[A-Z]/.test(newPassword),
        oneSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
        });
    }, [newPassword]);

        // Efecto para actualizar los estados con los valores actuales del usuario
    useEffect(() => {
            if (user) {
                setName(user.name || '');
                setLastName(user.lastname || '');
                setEmail(user.email || '');
                setUsername(user.username || '');
                // Agrega cualquier otro campo que necesites
            }
        }, [user]);  

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const [isChangePhotoOpen, setChangePhotoOpen] = useState(false);

    const handleOpenChangePhoto = () => {
        setChangePhotoOpen(true);
    };

    const handleCloseChangePhoto = () => {
        setChangePhotoOpen(false);
    };

    const onSave = (newProfilePicture) => {
        // Actualiza la información del usuario en el contexto
        setUser({...user, profilePicture: newProfilePicture});
    };


    const handleSavePersonalInfo = async (event) => {
        setLoadingPersonalInfo(true);
        event.preventDefault();
        try {
            const personalInfo = { name, lastname: lastName, username };
            const response = await axios.post(urlbackend + '/session/update-user', personalInfo, {withCredentials: true});
            setUser(response.data.user);
            setLoadingPersonalInfo(false);
            setPersonalInfoMsg('Personal information updated successfully!');
            setTimeout(() => window.location.reload(), 500)
        } catch (error) {
            setLoadingPersonalInfo(false);
            setPersonalInfoMsg('Failed to update personal information.');
        }
    };
    
    const handleSavePassword = async (event) => {
        setLoadingPassword(true);
        event.preventDefault();
        setSuccessMsg("");
        setErrorMsg("");
        if (newPassword !== confirmPassword) {
            setErrorMsg("Passwords do not match");
          return;
        }
    
        try {
            const passwordResponse = await axios.post(urlbackend + '/session/change-password', {
                currentPassword,
                newPassword
            }, { withCredentials: true });
    
            if (passwordResponse.data.success) {
                setLoadingPassword(false);
                setSuccessMsg(passwordResponse.data.message);
                
            setTimeout(() => window.location.reload(), 1000)
            } else {
                setLoadingPassword(false);
                setErrorMsg(passwordResponse.data.message);
            }
        } catch (error) {
            setLoadingPassword(false);
            setErrorMsg(error.response?.data?.message || "Failed to update password.");
        }
      };
    const handleDeleteAccount = async () => {
        setIsLoading(true);
        try {
            const response = await axios.delete(urlbackend + '/session/delete-user', { withCredentials: true });
            logout();  // Asúmase que logout es una función que limpia la sesión en el cliente
            navigate('/login');  // Redirigir al login
        } catch (error) {
            setIsLoading(false);
            console.error('Failed to delete account:', error);
        }
    };
    
    return(
        <section id="settings">
            <div className="account-information">
                <h2>Account Information</h2>
                <div className="settings-elements">
                    <div className="account-elements">
                        <div className="profile-picture">
                            <h4>Profile Picture</h4>
                            <div className="picture">
                                <img src={user?.profilePicture}></img>
                                <button onClick={handleOpenChangePhoto}><img src={Upload} id="upload"></img>Upload</button>
                            </div>
                            {isChangePhotoOpen && (
                                <ChangePhoto
                                    user={user}
                                    onClose={handleCloseChangePhoto}
                                    onSave={(newProfilePicture) => onSave(newProfilePicture)}
                                />
                            )}

                        </div>
                        <div className="username">
                            <form onSubmit={handleSavePersonalInfo}>
                                <div className="first-div">
                                    <label>First Name</label>
                                    <input type='text' value={name} onChange={(e) => setName(e.target.value)} required></input>
                                    <label style={{ marginTop: '10px' }}>Last Name</label>
                                    <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} required></input>
                                </div>
                                <div className="second-div">
                                    <div className="username-div"style={{ width: '100%'}}>
                                        <label>Username</label>
                                        <input  type='text' value={username} onChange={(e) => setUsername(e.target.value)} required></input>
                                    </div>
                                    <div className="personal-info-save">
                                        <button className="input-save" type='submit' disabled={LoadingPersonalInfo}>
                                        {LoadingPersonalInfo ? (
                                            <div className="loader"></div>
                                        ) : (
                                            "Save"
                                        )}
                                        </button>
                                        {personalInfoMsg && <p className="p-message">{personalInfoMsg}</p>}

                                    </div>

                                </div>

                            </form>
                            
                        </div>

                    </div>
                    <div className="email-security">
                        <div className="email-settings">
                            <form className="form1">
                                <div className="email-container">
                                    <label>Email</label>
                                    <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} readOnly style={{ backgroundColor: '#f0f0f0', color: '#333', opacity: 0.7 }} ></input>

                                </div>                    

                            </form>

                            <form className="form2" onSubmit={handleSavePassword}>
                                <div className="mayor-container">
                                    <h3>Change Password</h3>
                                    <label>Current Password</label>
                                    <div className="password-input-container">
                                        <input 
                                            type={showCurrentPassword ? 'text' : 'password'} 
                                            value={currentPassword} 
                                            onChange={(e) => setCurrentPassword(e.target.value)} 
                                            className="password-input"
                                            required
                                        />
                                        <button type="button" onClick={toggleCurrentPasswordVisibility} className="toggle-password">
                                            <img src={showCurrentPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                                        </button>
                                    </div>
                                    <label>New Password</label>
                                    <div className="password-input-container">
                                        <input 
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={newPassword} 
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className="password-input"
                                            required
                                        />
                                        <button type="button" onClick={toggleNewPasswordVisibility} className="toggle-password">
                                            <img src={showNewPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                                        </button>
                                    </div>
                                    
                                    <label>Confirm Password</label>
                                    <div className="password-input-container">
                                        <input 
                                            type={showConfirmPassword ? 'text' : 'password'} 
                                            value={confirmPassword} 
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="password-input"
                                            required
                                        />
                                        <button type="button" onClick={toggleConfirmPasswordVisibility} className="toggle-password">
                                            <img src={showConfirmPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                                        </button>
                                    </div>
                                </div>
                                <div className="input-container">
                                    <ul className="password-criteria">
                                        New password must contain:
                                        <li style={{ color: passwordValidation.minLength ? '#11d03a' : '#b6b6b6' }}>At least 8 characters</li>
                                        <li style={{ color: passwordValidation.oneNumber ? '#11d03a' : '#b6b6b6' }}>At least one number</li>
                                        <li style={{ color: passwordValidation.oneUpper ? '#11d03a' : '#b6b6b6' }}>At least one uppercase letter</li>
                                        <li style={{ color: passwordValidation.oneSpecial ? '#11d03a' : '#b6b6b6' }}>At least one special character</li>
                                    </ul>
                                    {successMsg && <p className="p-message2" style={{ color: '#17c662' }}>{successMsg}</p>}
                                    {errorMsg && <p className="p-message2" style={{ color: '#ff4a4a' }}>{errorMsg}</p>}                         
                                    <button className="email-button" type='submit' disabled={LoadingPassword}>
                                        {LoadingPassword ? (
                                            <div className="loader"></div>
                                        ) : (
                                            "Save"
                                        )}
                                        </button>
                                    
                                </div>

                            </form>
                        </div>
                        <div className="security-settings">
                            <h4>Remove Account</h4>
                            <p>Remove your account. It is important to notice that this is an irreversible action. So act with caution.</p>
                            <button onClick={() => setDeletePopupOpen(true)}>Remove account</button>
                            {isDeletePopupOpen && (
                                <DeleteAccountPopup
                                    onClose={() => setDeletePopupOpen(false)}
                                    onConfirm={handleDeleteAccount}
                                    isLoading={isLoading} 
                                />
                            )}

                        </div>

                    </div>

                </div>


            </div>

        </section>
    )
}

export default Settings;