import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/components/termsofservice.css'
import logo from '../../images/logo.svg';
import { BackgroundGradientAnimation } from '../subcomponents/background-gradient-animation';
import Linkedin from '../../images/landingpage/linkedin.svg';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {

  
    return(
        <BackgroundGradientAnimation>
            <Helmet>
                <title>Privacy Policy - QUBOC</title>
                <meta name="description" content="Understand how QUBOC collects, uses, and protects your personal data as you use our smart contract auditing services." />
                <meta property="og:title" content="Privacy Policy - QUBOC"/>
                <meta property="og:description" content="Read how your information is handled at QUBOC and how we protect your privacy."/>
                <meta property="og:url" content="https://www.quboc.io/privacy-policy"/>
                <link rel="canonical" href="https://www.quboc.io/privacy-policy"/>
            </Helmet>
            <section id="terms-of-services" style={{ cursor: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMjAsIDE2LCAxNikiPg0KICAgICAgICA8cGF0aCBkPSJNMTYgMiBRMjAgMTAgMjQgMTggUTI0LjUgMjAgMjQgMjIgUTIwIDIwIDE2IDE4IFExMiAyMCA4IDIyIFE3LjUgMjAgOCAxOCBRMTIgMTAgMTYgMloiIA0KICAgICAgICAgICAgIGZpbGw9IiMzNDNlNzYiIHN0cm9rZT0iI2Q3YjhkOCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCiAgICAgICAgPHBhdGggZD0iTTE2IDQgTDIzIDE4IFEyMi41IDE5IDIwIDE5IEwxNiAxNiBMMTIgMTkgUTkuNSAxOCAxOCAxNiBMMTYgNFoiIA0KICAgICAgICAgICAgIGZpbGw9IiMzNDNlNzYiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg=='), auto"}}>
                <header>
                    <Link to="/"><img src={logo}></img></Link>
                    <p>QUBOC</p>
                </header>
                <div className='terms-container'>
                    <h1>Privacy Policy</h1>
                    <h3>Last Updated: 13/08/2024</h3>
                    <p>Welcome to QUBOC! Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our services.</p>
                    <div className='terms' style={{ marginTop: '30px'}}>
                        <h2>Information We Collect</h2>
                        <p><b>a. Information You Provide:</b> This may include your name, email address, and any other information you provide when you register an account, use our services, or communicate with us.</p>
                        <p><b>b. Information Collected Automatically:</b> When you use our services, we may automatically collect information about your device and your usage of our services through cookies or similar technologies. This includes your IP address, browser type, operating system, and information about your interactions with our services.</p>
                        <h2>Use of Information</h2>
                        <p>We use the information we collect to:</p>
                        <ul>
                            <li>Provide, operate, and maintain our services</li>
                            <li>Improve, personalize, and expand our services</li>
                            <li>Understand and analyze how you use our services</li>
                            <li>Send you emails</li>
                            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webiste, and for marketing and promotional purposes</li>
                            <li>Find and prevent fraud</li>
                       </ul>
                       <h2>Sharing and Disclosure of Information</h2>
                        <p>We may share or disclose your information in the following situations:</p>
                        <ul>
                            <li><b>With Service Providers:</b> We engage certain trusted third parties to perform functions and provide services to us, including hosting and maintenance, error analysis, marketing, analytics, and customer service. We may share your personal information with these third parties, but only to the extent necessary to perform these functions and provide such services.</li>
                            <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with a legal obligation, protect and defend our rights or property, act in urgent circumstances to protect the personal safety of users of the services or the public, or protect against legal liability.</li>
                            <li><b>Business Transfers:</b> In the event of a merger, acquisition, reorganization, bankruptcy, or other similar event, your personal information may be part of the transferred assets.</li>
                        </ul>
                        <h2>Your Data Rights</h2>
                        <p>You have the right to access, correct, update, or request deletion of your personal information. You can also object to processing of your personal data, ask us to restrict processing of your personal data, or request portability of your personal data. If we have collected and processed your personal information with your consent, then you can withdraw your consent at any time.</p>
                        <h2>Data Security</h2>
                        <p>We take the security of your data seriously and use appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. While we strive to use commercially acceptable means to protect your personal information, no system or network can be guaranteed to be 100% secure.</p>
                        <h2>International Data Transfers</h2>
                        <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                        <h2>Changes to This Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this policy.</p>
                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us via email at support@quboc.io.</p>

                        <Link to="/"><button style={{ margin: '0 0 0 87%', padding: '10px 30px' }}>Back to Home</button></Link>

                    </div>
                </div>
                <footer>
                    <div className='footer-container'>
                        <div className='website-footer'>
                            <div className='logo-footer'>
                                <img src={logo} style={{ width: '40px'}} alt="quboc logo" />
                                <p style={{ margin: '0px', marginLeft: '10px', fontWeight: '700', fontSize: '2rem', color: 'white'}}>QUBOC</p>
                            </div>
                            <p style={{ fontWeight: '400', fontSize: '0.8rem', color: '#c4c4c4'}}>Securing smart contracts, empowering developers.</p>
                            <p style={{ fontWeight: '400', fontSize: '0.8rem', color: '#c4c4c4'}}>Copyright © 2024 - All rights reserved</p>
                        </div>
                        <div className='website-footer'>
                            <p>WEBSITE</p>
                            <div style={{lineHeight: '2rem'}}>
                                <a className='footer-a' href="/#about" target='blank'><li>About</li></a>
                                <a className='footer-a' href="/#solution" target='blank'><li>How it works</li></a>
                                <a className='footer-a' href='/#pricing' target='blank'><li>Pricing</li></a>
                                <a className='footer-a' href='/#faqs' target='blank'><li>FAQS</li></a>
                            </div>
                        </div>
                        <div className='website-footer'>
                            <div className='website-footer2'>
                                <p>LEGAL</p>
                                <div style={{lineHeight: '2rem'}}>
                                    <a className='footer-a' href="/terms-of-service"><li>Terms of services</li></a>
                                    <a className='footer-a' href="/privacy-policy"><li>Privacy policy</li></a>
                                </div>
                            </div>
                            <div className='website-footer2'>
                                <p>MEDIA</p>
                                <div style={{lineHeight: '2rem'}}>
                                    <Link to="/" ><img src={Linkedin} style={{ width: '25px'}}></img></Link>
                                </div>
                            </div>
                        </div>
                        <div className='website-footer'>
                                <p>CONTACT SUPPORT</p>
                                <p style={{ color: 'white', fontWeight: '400'}}>support@quboc.io</p>
                        </div>

                    </div>
                </footer>

            </section>

        </BackgroundGradientAnimation>

    )
}

export default PrivacyPolicy;