import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import '../styles/help.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import FAQList from './subcomponents/FAQlist';
import axios from 'axios';
import { useUser } from '../contexts/UserContext';

function Help() {
    const options = [
        { value: 'billing', label: 'Billing Issues' },
        { value: 'support', label: 'Technical Support' },
        { value: 'account', label: 'Account Management' },
        { value: 'feature', label: 'Feature Request' },
        { value: 'other', label: 'Other' }
    ];
    const { user } = useUser();
    const userEmail = user ? user.email : '';
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const urlbackendNode = process.env.REACT_APP_BACKEND_URL;
    const handleMessageChange = (e) => {
        if (e.target.value.length <= 1000) {
            setMessage(e.target.value);
        }
    };
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            height: '40px',
            fontSize: '0.9rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: state.isSelected ? '#5a3c5a' : '#5a3c5a',
            backgroundColor: state.isFocused ? '#e3d9e3' : 'white'
        }),
        valueContainer: (styles) => ({
            ...styles,
            height: '100%',
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center', 
        }),
        Input: (styles)  => ({
            height: '100%',
        }),
        control: (styles, { isFocused }) => ({
            ...styles,
            color: '#dfdfdf',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '10px',
            height: '40px',
            fontSize: '0.9rem',
            backgroundColor: 'white',
            borderColor: '#345c9c91',
            borderWidth: '2px',
            borderStyle: 'solid',
            boxShadow: 'none', 
            '&:hover': {
                borderColor: '#345c9c91'
            }
          }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? 'gray' : '#5a3c5a'
        }),
        indicatorSeparator: () => ({
            display: 'none'
        }),
        menu: styles => ({
            ...styles,
            margin: '0px',
            borderRadius: '10px',
            overflow: 'hidden',

          })
        
    };
    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const handleCategoryChange = (selectedOption) => {
        setCategory(selectedOption);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!category || !subject || !message) {
            alert('Please fill all the fields.');
            return;
        }

        const formData = {
            category: category.value,
            subject: subject,
            message: message,
            userEmail
        };
        setSuccessMessage('Sending your message...');
        try {
            const response = await axios.post(urlbackendNode + '/contact', formData);
            // Actualizar el mensaje de éxito con la respuesta del servidor
            setSuccessMessage(response.data.message || 'Your message has been sent successfully!');
            setTimeout(() => {
                setMessage('');
                setSubject('');
                setCategory(null);
                window.location.reload();
            },1000);
        } catch (error) {
            alert('Failed to send the message: ' + (error.response?.data?.message || error.message));
        }
    };
    return (
        <section id="help">
            <div className="faqs-container">
                <div className="faqs-text">
                    <h2>FAQs</h2>
                    <p>Explore our FAQs to find answers to common questions about our platform. If you need more help, feel free to reach out to us through our contact form below.</p>
                </div>
                <div className="faqst">
                    <FAQList />
                </div>
            </div>
            <div className="contact-form">
                <h2>Contact Us</h2>
                <form id="form" onSubmit={handleSubmit}>
                    <div className="category">
                        <Select
                            value={category}
                            onChange={handleCategoryChange}
                            placeholder="Category"
                            options={options}
                            styles={customStyles}
                            className="category-select" 
                        />
                        {successMessage && (
                            <div style={{ color: '#11d03a', textAlign:'center', marginTop: '10px' }}>
                                {successMessage}
                            </div>
                        )}
                    </div>
                    <div className="form-rest"> 
                        <div className="form-submit">
                            <input type='text' placeholder='Subject' id="subject" value={subject} onChange={handleSubjectChange} /> 
                            <button type='submit'>Submit</button> 
                        </div>
                        <textarea  
                        placeholder="Message" 
                        value={message}
                        onChange={handleMessageChange}
                        maxLength={1000}
                        style={{ resize: 'none' }}
                        className="Textaera" 
                        /> 
                    </div>
                </form>
                <form className="form-responsive" onSubmit={handleSubmit}>
                    <div className="category">
                    <Select
                            value={category}
                            onChange={handleCategoryChange}
                            placeholder="Category"
                            options={options}
                            styles={customStyles}
                            className="category-select" 
                        />
                    {successMessage && (
                            <div style={{ color: '#11d03', textAlign:'center', marginBottom: '10px' }}>
                                {successMessage}
                            </div>
                        )}

                    </div>
                    <div className="form-rest"> 
                        <div className="form-submit">
                            <input type='text' placeholder='Subject' id="subject" value={subject} onChange={handleSubjectChange} /> 

                            
                        </div>
                        <textarea  
                        placeholder="Message" 
                        value={message}
                        onChange={handleMessageChange}
                        maxLength={1000}
                        style={{ resize: 'none' }}
                        className="Textaera" 
                        /> 
                    </div>
                    <button type='submit'>Submit</button> 
                </form>

            </div>

        </section>
    )
}

export default Help;