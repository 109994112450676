import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Asegúrate de importar Link aquí
import { useUser } from '../contexts/UserContext';
import '../styles/header.css';
import logo from '../images/logo.svg';
import hamburguer from '../images/components/hamburger.png';
import audit from '../images/components/audit-logo.png';
import bill from '../images/components/billing-logo.png';
import help from '../images/components/help.png';
import usage from '../images/components/usage.png';
import logoutIcon from '../images/components/logout.png';
import axios from 'axios';


function Header() {
  const { user, logout } = useUser(); // Usar el contexto de usuario para obtener el usuario y la función logout
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const urlbackendNode = process.env.REACT_APP_BACKEND_URL;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await axios.get(urlbackendNode + '/logout');
      navigate('/login'); // Redirigir al usuario a la página de login
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <section id="header">
      <nav id="header-nav">
        <div className="logo-div">
          <Link to="/dashboard">
            <img src={logo} id="logo-image" alt="quboc logo" />
          </Link>
          <Link to="/dashboard">
            <p id="quboc-title">QUBOC</p>
          </Link>
        </div>
        <div id="nav-responsive">
          <Link to="/dashboard"><li>New Audit</li></Link>
          <Link to="/dashboard/usage"><li>Usage</li></Link>
          {user && user.userType !== 'Business' && (
            <Link to="/dashboard/plans-and-billings">
              <li>Plans and Billing</li>
            </Link>
          )}

          <Link to="/dashboard/help-support"><li>Help and Support</li></Link>
        </div>
        <div id="nav-user">
          <Link to="/dashboard/settings" style={{ marginTop: "5px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" aria-haspopup="true" aria-expanded="false">
              <path fillRule="evenodd" d="M11.568 3.5a1 1 0 0 0-.863.494l-.811 1.381A3.001 3.001 0 0 1 7.33 6.856l-1.596.013a1 1 0 0 0-.858.501l-.439.761a1 1 0 0 0-.004.992l.792 1.4a3 3 0 0 1 0 2.954l-.792 1.4a1 1 0 0 0 .004.992l.439.76a1 1 0 0 0 .858.502l1.596.013a3 3 0 0 1 2.564 1.48l.811 1.382a1 1 0 0 0 .863.494h.87a1 1 0 0 0 .862-.494l.812-1.381a3.001 3.001 0 0 1 2.563-1.481l1.596-.013a1 1 0 0 0 .86-.501l.438-.761a1 1 0 0 0 .004-.992l-.793-1.4a3 3 0 0 1 0-2.954l.793-1.4a1 1 0 0 0-.004-.992l-.439-.76a1 1 0 0 0-.858-.502l-1.597-.013a3 3 0 0 1-2.563-1.48L13.3 3.993a1 1 0 0 0-.862-.494h-.87ZM8.98 2.981A3.001 3.001 0 0 1 11.568 1.5h.87c1.064 0 2.049.564 2.588 1.481l.811 1.382a1 1 0 0 0 .855.494l1.596.013a3 3 0 0 1 2.575 1.502l.44.76a3 3 0 0 1 .011 2.975l-.792 1.4a1 1 0 0 0 0 .985l.792 1.401a3 3 0 0 1-.012 2.974l-.439.761a3.001 3.001 0 0 1-2.575 1.503l-1.597.012a1 1 0 0 0-.854.494l-.811 1.382a3.001 3.001 0 0 1-2.588 1.481h-.87a3.001 3.001 0 0 1-2.588-1.481l-.811-1.382a1 1 0 0 0-.855-.494l-1.596-.012a3.001 3.001 0 0 1-2.576-1.503l-.438-.76a3 3 0 0 1-.013-2.975l.793-1.4a1 1 0 0 0 0-.985l-.793-1.4a3 3 0 0 1 .013-2.975l.438-.761A3.001 3.001 0 0 1 5.718 4.87l1.596-.013a1 1 0 0 0 .855-.494l.81-1.382Z" clipRule="evenodd"></path>
              <path fillRule="evenodd" d="M12.003 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM8.502 12a3.5 3.5 0 1 1 7 .001 3.5 3.5 0 0 1-7-.001Z" clipRule="evenodd"></path>
            </svg>
          </Link>
          <button className={`profile-logout ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <img src={user?.profilePicture} alt="Profile" className='profile-img' />
            <ul className={`logout ${isOpen ? 'open' : ''}`}>
              <li onClick={handleLogout}>
                <img src={logoutIcon} className="logout-img" alt="Logout" />
                Logout
              </li>
            </ul>
          </button>
        </div>
        <div className="ul-responsive">
          <button className={`hamburger ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <img src={hamburguer} id="hamburguer-image" alt="Menu" />
          </button>
          <ul className={`mobile-menu ${isOpen ? 'open' : ''}`}>
            <Link to="/"><img src={audit} className="sidebar-logo" alt="New Audit" /><li>New Audit</li></Link>
            <Link to="/dashboard/usage"><img src={usage} className="sidebar-logo" alt="Usage" /><li>Usage</li></Link>
            {user && user.userType !== 'Business' && (
              <Link to="/dashboard/plans-and-billings"><img src={bill} className="sidebar-logo" alt="Plans and Billing" /><li>Plans and Billing</li></Link>
            )}         
            <Link to="/dashboard/help-support"><img src={help} className="sidebar-logo" alt="Help and Support" /><li>Help and Support</li></Link>
            <Link to="/login"><li onClick={handleLogout}><img src={logoutIcon} className="logout-img" alt="Logout" />Logout</li></Link>
          </ul>
          <div className="nav-user-responsive">
            <Link className="settings-li" to="/dashboard/settings" style={{ marginTop: "5px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24" aria-haspopup="true" aria-expanded="false">
                <path fillRule="evenodd" d="M11.568 3.5a1 1 0 0 0-.863.494l-.811 1.381A3.001 3.001 0 0 1 7.33 6.856l-1.596.013a1 1 0 0 0-.858.501l-.439.761a1 1 0 0 0-.004.992l.792 1.4a3 3 0 0 1 0 2.954l-.792 1.4a1 1 0 0 0 .004.992l.439.76a1 1 0 0 0 .858.502l1.596.013a3 3 0 0 1 2.564 1.48l.811 1.382a1 1 0 0 0 .863.494h.87a1 1 0 0 0 .862-.494l.812-1.381a3.001 3.001 0 0 1 2.563-1.481l1.596-.013a1 1 0 0 0 .86-.501l.438-.761a1 1 0 0 0 .004-.992l-.793-1.4a3 3 0 0 1 0-2.954l.793-1.4a1 1 0 0 0-.004-.992l-.439-.76a1 1 0 0 0-.858-.502l-1.597-.013a3 3 0 0 1-2.563-1.48L13.3 3.993a1 1 0 0 0-.862-.494h-.87ZM8.98 2.981A3.001 3.001 0 0 1 11.568 1.5h.87c1.064 0 2.049.564 2.588 1.481l.811 1.382a1 1 0 0 0 .855.494l1.596.013a3 3 0 0 1 2.575 1.502l.44.76a3 3 0 0 1 .011 2.975l-.792 1.4a1 1 0 0 0 0 .985l.792 1.401a3 3 0 0 1-.012 2.974l-.439.761a3.001 3.001 0 0 1-2.575 1.503l-1.597.012a1 1 0 0 0-.854.494l-.811 1.382a3.001 3.001 0 0 1-2.588 1.481h-.87a3.001 3.001 0 0 1-2.588-1.481l-.811-1.382a1 1 0 0 0-.855-.494l-1.596-.012a3.001 3.001 0 0 1-2.576-1.503l-.438-.76a3 3 0 0 1-.013-2.975l.793-1.4a1 1 0 0 0 0-.985l-.793-1.4a3 3 0 0 1 .013-2.975l.438-.761A3.001 3.001 0 0 1 5.718 4.87l1.596-.013a1 1 0 0 0 .855-.494l.81-1.382Z" clipRule="evenodd"></path>
                <path fillRule="evenodd" d="M12.003 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM8.502 12a3.5 3.5 0 1 1 7 .001 3.5 3.5 0 0 1-7-.001Z" clipRule="evenodd"></path>
              </svg>
            </Link>
            <img src={user?.profilePicture} alt="Profile" className='profile-img' />
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Header;
