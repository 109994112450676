import React from 'react';
import close from '../../images/components/close.svg';

function DeleteAccount({ onClose, onConfirm, isLoading }) {
    return (
        <div className="backdrop">
            <div className="modal-content">
                <div className="title-close-popup">
                    <button  onClick={onClose} style={{ border: '2px solid #c389d194'}}><img src={close}></img></button>
                    <h2>Confirm Account Deletion</h2>
                </div>
                <p>Are you sure you want to delete your account? This action cannot be undone and you will lose all your data.</p>
                <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px 0px' }} className='delete-account-buttons'>
                    <button onClick={onConfirm} id="delete-button">
                        {isLoading ? <div className="loader"></div> : 'Delete'}
                    </button>
                    <button onClick={onClose} id="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteAccount;
