import React, { useState } from 'react';
import '../../styles/faqlist.css'

// Componente individual para cada pregunta
const FAQ = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="faq-item">
            <button className="faq-question" onClick={toggle}>
                {question}
                <span>{isOpen ? '-' : '+'}</span>
            </button>
            <div className={`faq-answer ${isOpen ? 'open' : 'closed'}`}>
                {answer}
            </div>
        </div>
    );
};

// Componente contenedor para la lista de preguntas
const FAQList = () => {
    const faqs = [
        { question: "How do I start a smart contract audit in QUBOC?", answer: "To start an audit, simply log into your account, go to the 'Audits' section of the dashboard, and select 'New Audit.' Paste your smart contract code into the field provided and press “Audit.” Our automated system will analyze the contract and provide a detailed report with the results." },
        { question: "What types of vulnerabilities can QUBOC detect?", answer: "QUBOC is designed to detect a wide range of common smart contract vulnerabilities, including re-entry, integer overflow and underflow, exception handling errors, and visibility issues, among others." },
        { question: "Is it safe to upload my smart contract code to QUBOC?", answer: "Safety is our top priority. Your smart contract code is entered directly into the online editor and is not stored on our servers. Once you audit and reload the page, the code is deleted from the editor, ensuring no copy is retained." },
        { question: "How much does it cost to use QUBOC?", answer: "QUBOC offers one free audit per month for registered users. For additional audits and access to advanced features, we offer a premium plan. All payments are handled securely through Stripe. Visit our 'Plans and Prices' page for more information." },
        { question: "Can I cancel my subscription at any time?", answer: "Yes, you can cancel your subscription at any time from your user profile. Cancellation will be effective at the end of the current billing period, and you will be able to continue using premium services until then.." },
        { question: "Can I receive a refund?",  answer: "Refunds are handled on a case-by-case basis. If you are not satisfied with our service, please contact us within the first 14 days of your subscription or purchase to request a refund. Refund requests submitted after this period may not be eligible. To proceed with a refund, contact our support team with your account details and reason for dissatisfaction. Our team will review your request and respond as promptly as possible." },       
        { question: "How can I get support if I encounter a problem?", answer: "If you have problems or need assistance, you can contact our technical support team through the 'Help' section on the platform, or email us directly. Our team is available to make sure your experience with QUBOC is optimal." },
    ];

    return (
        <div className="faq-list">
            {faqs.map((faq, index) => (
                <FAQ key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>
    );
};

export default FAQList;
