import React, { useState, useEffect, useRef } from 'react';
import $ from 'jquery';
import { useUser, UserProvider } from '../contexts/UserContext';
import '../styles/sidebar.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import logout from '../images/components/logout.png';
import audit from '../images/components/audit-logo.png';
import bill from '../images/components/billing-logo.png'
import help from '../images/components/help.png'
import usage from '../images/components/usage.png'


function Sidebar() {
    const { user } = useUser(); 
    return(
        <section id="sidebar">
            <nav className="nav-sidebar">
                <ul className="nav-sidebar-ul">
                    <div className="nav-sidebar-div">
                        <Link to="/dashboard"><img src={audit} className="sidebar-logo"></img><li>New Audit</li></Link>
                        <Link to="/dashboard/usage"><img src={usage} className="sidebar-logo"></img><li>Usage</li></Link>
                        {user && user.userType !== 'Business' && (
                        <Link to="/dashboard/plans-and-billings">
                            <img src={bill} className="sidebar-logo"></img>
                            <li>Plans and Billing</li>
                        </Link>
                        )}
                        <Link to="/dashboard/help-support"><img src={help} className="sidebar-logo"></img><li>Help and support</li></Link>

                    </div>
                    <div className="logout-sidebar">
                        <Link to="/login"><li><img src={logout} className="sidebar-logo"></img>Logout</li></Link>
                    </div>

                </ul>


            </nav>        
        </section>
            
    )

}

export default Sidebar;