import React, { useState, useEffect, useRef } from 'react';
import MonacoEditor from './monaco-editor';
import { useUser } from '../contexts/UserContext';
import '../styles/dashboard.css'; 
import { Link } from 'react-router-dom';
import runaudit from '../images/components/run-audit.png';
import close from '../images/components/close.svg';
   




function Dashboard() {

  const { user, updateUser  } = useUser();
  const editorRef = useRef(null);
  const [message, setMessage] = useState('The results of the audit will appear here once you run the solidity code in the editor, you can download the results in PDF.');
  const [isRunningAudit, setIsRunningAudit] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [showMessage, setShowMessage] = useState(true);
  const [sessionUserID, setSessionUserID] = useState(null);
  const urlbackendNode = process.env.REACT_APP_BACKEND_URL;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');


  const getContainerStyle = () => {
    if (isRunningAudit) {
      return {
        background: 'rgb(80, 5, 61)',
        animation: 'animateBg 10s linear infinite'
      };
    } else {
      return {
        background: `radial-gradient(circle at 10% 30%, #752f95, transparent 50%), 
                     radial-gradient(circle at 75% 20%, #2be2e1, transparent 50%), 
                     radial-gradient(circle at 85% 80%, #3198ff, transparent 50%), 
                     radial-gradient(circle at 21% 80%, #c434b8, transparent 50%)`
      };
    }
  };



  useEffect(() => {
    fetch(urlbackendNode+'/auth/check', {
      method: 'GET',
      credentials: 'include', // Incluye las credenciales (cookies) en la solicitud
    })
      .then((res) => res.json())
      .then((data) => {
        //console.log('Session data:', data.user._id);
        setSessionUserID(data.user._id);
      })
      .catch((error) => {

        console.error('Error fetching session data:', error);
      });
  }, []);


  const runAudit = async () => {
    setShowMessage(false);  
    setIsRunningAudit(true);
    setPdfUrl(null); 
    setShowAlert(false);
    if (editorRef.current && user) {
      const code = editorRef.current.getValue();
       // Eliminar espacios en blanco y verificar si el código está efectivamente vacío o solo tiene comentarios
      const lines = code.split('\n').filter(line => line.trim() !== '' && !line.trim().startsWith('//'));

      if (lines.length === 0) {
        setIsRunningAudit(false);
        setShowMessage(true);
        setMessage("No valid Solidity code provided. Please ensure your code isn't just comments or empty lines.");
        return;
      }

      // Verificación del límite de auditorías antes de enviar el código a Flask
      if (user.auditsUsed >= user.auditsLimit) {
        setAlertMessage('Audit limit reached. Upgrade your plan for more audits.');
        setShowAlert(true);
        setIsRunningAudit(false);
        return;
      }


      const flaskApi = "https://quboc.io/api"; 
      try {
        const response = await fetch(flaskApi + '/audit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code, userId: user._id })
        });

        if (response.status === 200) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          setShowMessage(false);
          setIsRunningAudit(false);
          // Envía la solicitud para registrar la auditoría
          const auditResponse = await fetch(urlbackendNode + '/audit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_id: sessionUserID })      
          });
          if (auditResponse.ok) {
            const responseData = await auditResponse.json();
            updateUser(responseData.user);  // Asegúrate de que esta línea se ejecuta y que responseData.user contiene los datos correctos.
          } else {
            const errorData = await auditResponse.json();
            setMessage(errorData.message || 'Error updating audit info.');
          }
        } else {
          const errorData = await response.json();
          setIsRunningAudit(false);
          setShowMessage(true);
          setMessage(`Audit request failed: ${errorData.error}`);
        }
      } catch (error) {
        setIsRunningAudit(false);
        setMessage('Error in audit. Please try again.');
  
        setShowMessage(true);
      }
    } else {
      setIsRunningAudit(false);
      setShowMessage(true);
      setMessage('Editor not mounted or user not loaded.');
      
    }
  };
  return (
    <section id="dashboard">
      <div className="diagrams">
        <div className="editor">
          <div className="titles">
            <h2>Code</h2>
            <button onClick={runAudit}>Run Audit <img src={runaudit} alt="Run Audit" /></button>
            {showAlert && (
              <div className="alert">
                <button onClick={() => setShowAlert(false)} id="close-alert"><img src={close} alt="close-img"></img></button>
                <p>{alertMessage}</p> 
                <Link to="/dashboard/plans-and-billings"><button id="alert-plans">See Plans</button></Link>
              </div>
            )}
          </div>
          <div className="code-editor">
            <MonacoEditor ref={editorRef} />
          </div>
        </div>
        <div className="result">
          <h2>Result</h2>
          <div className="result-container" style={getContainerStyle()}>
            <div className="message" style={{ display: showMessage ? 'block' : 'none' }}>
                <p>{message}</p>
              </div>
              {isRunningAudit && <div className="spinner">
                {Array.from({ length: 20 }, (_, i) => (
                  <span style={{ '--i': i + 1 }} key={i}></span>
                ))}
                <p>Loading</p>
              </div>}

            {pdfUrl && (
              <iframe src={pdfUrl} width="100%" height="100%" title="Audit Report"></iframe>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};


export default Dashboard;