import React from 'react';
import { Canvas } from '@react-three/fiber';
import SolidityIcon3D from './SolidityIcon3D';
import Secure from '../../images/landingpage/secure.svg'

const AboutQUBOC = () => {
  return (
    <div className='canvas' id="about">
      <Canvas>
        <ambientLight intensity={0.5} />
        <pointLight position={[0, 0, 0]} intensity={5} />
        <SolidityIcon3D />
      </Canvas>
      <div className='about-text'>
        <h4>Secure & Efficient<img src={Secure} style={{ width: '50px'}}></img></h4>
        <h2>Empowering Smart Contract Development</h2>
        <p>Welcome to QUBOC, your ultimate platform for instant Solidity contract audits. Designed for both beginners and seasoned developers, QUBOC ensures your smart contracts are secure, efficient, and ready for deployment. Experience the peace of mind that comes with reliable, fast, and easy-to-use auditing solutions.</p>
      </div>
    </div>
  );
};

export default AboutQUBOC;
