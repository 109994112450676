
import React from 'react';
import { useUser } from '../contexts/UserContext.js'; // Asegúrate de importar correctamente

const LogoutButton = () => {
  const { logout } = useUser(); // Obtener la función logout del UserContext
  

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = '/login'; // Redirigir a la página de login después del logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <button onClick={handleLogout}>
      Log Out
    </button>
  );
};

export default LogoutButton;
