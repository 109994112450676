import React, { useRef, useEffect, useState } from 'react';
import * as monaco from 'monaco-editor';
import '../../styles/components/typingeffect.css'
import CodeExample from '../../images/landingpage/code-example.png'


const codeSample = `// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;
contract SimpleBank {
    mapping(address => uint) private balances;
    mapping(address => bool) public enrolled;

    event LogDepositMade(address indexed accountAddress, uint amount);

    modifier isEnrolled() {
        require(enrolled[msg.sender], "User not enrolled");
        _;
    }
    function enroll() public returns (bool) {
        require(!enrolled[msg.sender], "User already enrolled");
        enrolled[msg.sender] = true;
        return enrolled[msg.sender];
    }
    function deposit() public payable isEnrolled returns (uint) {
        require(msg.value > 0, "Deposit amount must be greater than 0");
        balances[msg.sender] += msg.value;
        emit LogDepositMade(msg.sender, msg.value);
        return balances[msg.sender];
    }
    function balance() public view isEnrolled returns (uint) {
        return balances[msg.sender];
    }
}`;

const result = `Audit Report:
- Function enroll: No issues found.
- Function deposit: No issues found.
- Function balance: No issues found.
- General: Ensure to handle edge cases and potential gas limit issues.`;

const TypingEffect = () => {
    const editorRef = useRef(null);
    const containerRef = useRef(null);
    const [percentage, setPercentage] = useState(93.24);

    useEffect(() => {
        const editor = monaco.editor.create(containerRef.current, {
            value: codeSample,
            language: 'sol',
            theme: 'vs-dark',
            readOnly: true,
            minimap: { enabled: false },
            lineNumbers: 'on',
            automaticLayout: true,
            scrollBeyondLastLine: false,
            fontSize: 12.5,
            wordWrap: 'on',
            scrollbar: {
                vertical: 'hidden',
                horizontal: 'hidden'
            },
            folding: false
        });
        editorRef.current = editor;

        // Deshabilitar click en el editor
        editor.onMouseDown((e) => {
            e.event.preventDefault();
        });

        // Eliminar el espacio en blanco debajo de las líneas de código
        editor.getModel().onDidChangeContent(() => {
            editor.getModel().setEOL(monaco.editor.EndOfLineSequence.LF);
        });

        // Agregar un estilo personalizado para cambiar el color de las letras blancas
        const darkTheme = monaco.editor.defineTheme('custom-dark', {
            base: 'vs-dark',
            inherit: true,
            rules: [
                { token: '', foreground: '73e2b7' },
                { token: 'keyword', foreground: '7d9eff' },
                { token: 'number', foreground: 'b5cea8' },
                { token: 'string', foreground: 'ce9178' },
                { token: 'variable', foreground: '9cdcfe' },
                { token: 'type', foreground: '4ec9b0' }
            ],
            colors: {
                'editor.background': '#1e1e1e'
            }
        });

        monaco.editor.setTheme('custom-dark');

        // Ajustar la altura del contenedor para mostrar todas las líneas sin scroll
        const lineCount = editor.getModel().getLineCount();
        const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
        containerRef.current.style.height = `${lineCount * lineHeight}px`;

        return () => editor.dispose();


    }, []);
    // Estilo para el gráfico de dona
    const donutStyle = {
        width: '125px',
        height: '125px',
        borderRadius: '50%',
        background: `conic-gradient(
            #0bc10b 0% ${percentage}%, 
            #eee ${percentage}% 100%
        )`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        fontSize: '15px',
        color: '#333',
    };
    
    const innerCircleStyle = {
        width: '75px',  // Ajusta según el tamaño del "agujero" deseado
        height: '75px',
        borderRadius: '50%',
        background: '#fff',
        position: 'absolute',
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50px' }}>
            <div style={{ display: 'flex', width: '100%', height: '580px',  maxWidth: '1200px', boxShadow: '0px 0px 10px 0px #ffffff', borderRadius: '20px' }}>
                <div ref={containerRef} className='monaco-container'/>
                <div className="pdf-viewer" style={{ flex: 1, backgroundColor: 'rgb(255 255 255)', color: 'black', padding: '20px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', overflow: 'hidden' }}>
                    <h2>Security Audit Report</h2>
                    <div className='graphs' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div className='score'>
                            <h3>Final Score</h3>
                            <div className="donut" style={donutStyle}>    
                                <div style={innerCircleStyle}></div>
                                <span className="donut-text">{percentage.toFixed(2)}%</span>                               
                            </div>
                            <p>Summary: Reliable: The contract has proven to be highly secure under the tests carried out. No critical problems were detected.</p>

                        </div>
                        <div className='vulnerabilities'>
                            <h3>Vulnerabilities</h3>
                            
                            <div className="bar-container">
                                <div className="y-axis-label">Count</div>
                                <div className="bar high-severity">
                                    <span>0</span>
                                    <div className="bar-label">High</div>
                                </div>
                                <div className="bar medium-severity">
                                    <span style={{ position: 'absolute', top: '-20px'}}>2</span>
                                    <div className="bar-label">Medium</div>
                                </div>
                                <div className="bar low-severity">
                                    <span style={{ position: 'absolute', top: '-20px'}}>1</span>
                                    <div className="bar-label">Low</div>
                                </div>
                            </div>
                            <div className="axis-labels">
                                <div className="severity-labels">
                                    <span>Severity</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='code-example'>
                        <h4>locked-ether (Severity: Medium)</h4>
                        <p><b>Description: </b>Contract locking ether found: Contract SimpleBank has payable functions:- SimpleBank.deposit() But does not have a function to withdraw the ether</p>
                        <p><b>Lines: </b>18, 19, 20, 21, 22, 23</p>
                        <img src={CodeExample} style={{height: '100px', borderRadius: '10px'}}></img>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default TypingEffect;