import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../styles/plans-billing.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import ConfirmPlanChangePopup from './popups/changePlan';

function PlanBillings() {
    const [currentPlan, setCurrentPlan] = useState('');
    const [isAnnual, setIsAnnual] = useState(false);
    const [loadingPlan, setLoadingPlan] = useState(''); 
    const [loadingBilling, setLoadingBilling] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [loadingPopup, setLoadingPopup] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorUpgrade, setErrorUpgrade] = useState('');
    const [error, setError] = useState(null);
    const urlbackend = process.env.REACT_APP_BACKEND_URL;
    const prices = {
      basic: { monthly: 0, annual: 0 * 12 * 0.8 },
      pro: { monthly: 50, annual: 50 * 0.8 },
      ultra: { monthly: 100, annual: 100 * 0.8 },
      business: { monthly: 200, annual: 200*0.8},
    };
    useEffect(() => {
        const fetchCurrentPlan = async () => {
            try {
                const response = await axios.get(urlbackend + '/session/current_user', { withCredentials: true });
                setCurrentPlan(response.data.user.current_plan);
            } catch (error) {
                console.error('Error fetching current plan:', error);
            }
        };

        fetchCurrentPlan();
    }, []);
    const handleManageBillingClick = async () => {
        setLoadingBilling(true); 
        setError(null);
        try {
            const response = await axios.get(urlbackend + `/create-portal-session`, {
                withCredentials: true
            });
            // Redirecciona al URL del portal de Stripe
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error opening Stripe billing management:', error);
            setError('Failed to open Stripe billing management.');
        } finally {
            setLoadingBilling(false);
        }
    };
    const handlePlanChangeClick = async (plan) => {
        setSelectedPlan(plan);
        setLoadingPlan(plan);
        const cycleToSend = isAnnual ? 'annual' : 'monthly';
    
        try {
            const response = await axios.post(urlbackend + '/change-plan', {
                plan,
                cycle: cycleToSend
            }, { withCredentials: true });
    
            if (response.data.url) {
                // Redirect to Stripe checkout if needed
                window.location.href = response.data.url;
            } else {
                // Open confirmation popup
                setLoadingPlan('');
                setIsPopupOpen(true);
            }
        } catch (error) {
            console.error('Error during plan change initiation:', error);
            setErrorUpgrade('Failed to initiate plan change.');
            setLoadingPlan('');
        }
    };
    const confirmPlanChange = async (plan, cycle) => {
        try {
            setLoadingPopup(true);
            // Direct call to backend to change the plan without user needing to enter payment details
            const response = await axios.post(urlbackend + '/apply-plan-change', {
                plan,
                cycle
            }, { withCredentials: true });
    
            if (response.status === 200) {
                setSuccessMessage('Plan changed successfully!');
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                throw new Error('Failed to change plan');
            }
        } catch (error) {
            console.error('Error confirming plan change:', error);
            setErrorUpgrade(error.response?.data?.error || 'Failed to confirm plan change.');
        } finally {
            setLoadingPopup(false);
            setIsPopupOpen(false);
        }
    };
    const closePopup = () => {
        setIsPopupOpen(false);
        setLoadingPlan('');
        setErrorUpgrade('');
    };
    const handleDeleteChangeClick = async (plan) => {
        setLoadingCancel(true);
        setError(null);
        try {
            const response = await axios.get(urlbackend + `/create-portal-session`, {
                withCredentials: true
            });
            // Redirecciona al URL del portal de Stripe
            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error opening Stripe billing management:', error);
            setError('Failed to open Stripe billing management.');
        } finally {
            setLoadingCancel(false);
        }
    };
    

    return (
        <section id="plans-billing">
                <div className="body-class">
                <h1>Plans</h1>
                <div id="pricing-boxes">
                    <div className="toggle-buttons">
                        <button onClick={() => setIsAnnual(false)} className={!isAnnual ? "active" : ""} style={{ borderTopLeftRadius: '19px', borderBottomLeftRadius: '19px' }}>Monthly</button>
                        <button onClick={() => setIsAnnual(true)} className={isAnnual ? "active" : ""} style={{ borderTopRightRadius: '19px', borderBottomRightRadius: '19px' }}>Annual</button>
                            <div className="discount-tooltip">
                                Save 20%
                                <div className="tooltip-arrow"></div>
                            </div>
                    </div>
                    <div className="pricing-lelo">
                        <div className={`pricing-box ${currentPlan === 'Free' ? 'active-plan' : ''}`}>
                            <h3>Free</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.basic.annual : prices.basic.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Ideal for new developers testing basic security on small projects.</p>
                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>3 Audits /Month</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>

                            </div>


                            {currentPlan === 'Free' ? (
                                <div className="current-plan-label">Current Plan</div>
                            ) : (
                                <button className="plan" onClick={() => handlePlanChangeClick('Free')} disabled={loadingPlan === 'Free'}>
                                    {loadingPlan === 'Free' ? <div className="loader"></div> : 'Upgrade'}
                                </button>

                            )}
                        </div>
                        <div className={`pricing-box ${currentPlan === 'Pro' ? 'active-plan' : ''}`}>
                            <h3>Pro</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.pro.annual : prices.pro.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Suitable for professionals needing regular audits for ongoing projects.</p>
                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>100 Audits /Month</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>
                            </div>
                            {currentPlan === 'Pro' ? (
                                <div className="current-plan-label">Current Plan</div>
                            ) : (
                                <button className="plan" onClick={() => handlePlanChangeClick('Pro')} disabled={loadingPlan === 'Pro'}>
                                    {loadingPlan === 'Pro' ? <div className="loader"></div> : 'Upgrade'}
                                </button>
                            )}
                        </div>
                        <div className={`pricing-box ${currentPlan === 'Ultra' ? 'active-plan' : ''}`}>
                            <h3>Ultra</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.ultra.annual : prices.ultra.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Best for advanced users with multiple projects needing regular security checks.</p>
                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>300 Audits /Month</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>
                            </div>
                            {currentPlan === 'Ultra' ? (
                                <div className="current-plan-label">Current Plan</div>
                            ) : (
                                <button className="plan" onClick={() => handlePlanChangeClick('Ultra')} disabled={loadingPlan === 'Ultra'}>
                                    {loadingPlan === 'Ultra' ? <div className="loader"></div> : 'Upgrade'}
                                </button>
                            )}
                        </div>
                        <div className={`pricing-box ${currentPlan === 'Business' ? 'active-plan' : ''}`}>
                            <h3>Business</h3>
                            <div className="price-f">
                                 <div className="price">
                                    <p className="number-price">${isAnnual ? prices.business.annual : prices.business.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Unlimited, customizable audits tailored to your specific project needs.</p>


                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Unlimited audits</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>
                            </div>
                            {currentPlan === 'Business' ? (
                                <div className="current-plan-label">Current Plan</div>
                            ) : (
                                <button className="plan" onClick={() => handlePlanChangeClick('Business')} disabled={loadingPlan === 'Business'}>
                                    {loadingPlan === 'Business' ? <div className="loader"></div> : 'Upgrade'}
                                </button>
                            )}
                        </div>
                        {isPopupOpen && (
                            <ConfirmPlanChangePopup
                                onClose={closePopup}
                                onConfirm={(plan, cycle) => confirmPlanChange(plan, cycle)}
                                isLoading={loadingPopup}
                                plan={selectedPlan}
                                cycle={isAnnual ? 'annual' : 'monthly'}
                                error={errorUpgrade}
                                successMessage={successMessage}
                            />
                        )}
                    </div>
                </div>
                <div className="subscription" style={{ display: currentPlan === 'Free' ? 'none' : 'flex' }}>
                    <div className="manage-billing">
                        <h2 className="plan-h2">Manage Billing</h2>
                        <p>We integrate Stripe for safe and secure payments and subscriptions. You can manage your subscription, seats, invoices, credit card details, and more directly through Stripe.</p>
                        <button id="manage-button" onClick={handleManageBillingClick} disabled={loadingBilling}>
                            {loadingBilling ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    <svg id="svg-stripe" viewBox="0 0 24 24">
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                                    </svg>
                                    Manage Billing in Stripe
                                </>
                            )}
                        </button>
                        {error && <p className="error" style={{ position: 'absolute', width: '130px', textAlign: 'center', top: '65%'}}>{error}</p>}
                    </div>
                    <div className="cancel-subscription">
                        <h2 className="plan-h2">Cancel Subscription</h2>
                        <p>Cancel your subscription. When you cancel the subscription, you will be downgraded to the free plan and lose access to the features on your paid plan.</p>
                        <button id="cancel-subscription" style={{ width: '205px'}} onClick={handleDeleteChangeClick} disabled={loadingCancel}>
                            {loadingCancel ? (
                                <div className="loader"></div>
                            ) : (
                                <>
                                    Cancel Subscription
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default PlanBillings;