import React, { useState } from 'react';
import axios from 'axios';
import '../styles/forgotpassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const urlbackendNode = process.env.REACT_APP_BACKEND_URL;


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage(''); // Limpiar mensajes previos
    setErrorMessage('');
    try {
      const response = await axios.post(urlbackendNode + '/forgot-password', { email });
      setSuccessMessage(response.data);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data); 
    }
  };

  return (
    <section id="forgot-password-container">
        <div className="forgot-password">
            {!successMessage && <h2>Forgot Password</h2>}
            {!successMessage && (
              <form onSubmit={handleSubmit}>
                  <label>Email:</label>
                  <input 
                  type="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                  required
                  autoComplete="email"
                  />
                  {errorMessage && <p style={{ color: '#ff4a4a', fontSize: '0.9rem', textAlign: 'center', marginTop: '10px' }}>{errorMessage}</p>}
                  <button type="submit">{isLoading ? <div className="loader"></div> : 'Send Reset Link'}</button>
              </form>
            )}
            {successMessage && <p style={{ color: '#ffffff', fontSize: '1.1rem', textAlign: 'center', margin: '40px 0px', lineHeight: '1.8rem' }}>{successMessage}</p>}
        </div>
    </section>
  );
};

export default ForgotPassword;
