import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/pricing.css'
import Star from '../../images/landingpage/star.svg'

function Pricing() {
    const [isAnnual, setIsAnnual] = useState(false);
    const prices = {
      basic: { monthly: 0, annual: 0 * 12 * 0.8 },
      pro: { monthly: 50, annual: 50 * 0.8 },
      ultra: { monthly: 100, annual: 100 * 0.8 },
      business: { monthly: 200, annual: 200 * 0.8},
    };
    return (
        <section id="pricing">
            <div className='pricing-container'>
                <h4>Pricing</h4>
                <h2>Unlock Full Potential with Exclusive Access</h2>
                <div className="toggle-buttons">
                        <button onClick={() => setIsAnnual(false)} className={!isAnnual ? "active" : ""} style={{ borderTopLeftRadius: '19px', borderBottomLeftRadius: '19px' }}>Monthly</button>
                        <button onClick={() => setIsAnnual(true)} className={isAnnual ? "active" : ""} style={{ borderTopRightRadius: '19px', borderBottomRightRadius: '19px' }}>Annual</button>
                            <div className="discount-tooltip">
                                Save 20%
                                <div className="tooltip-arrow"></div>
                            </div>
                </div>
                <div className='pricing-boxes-landing'>
                    <div className='pricing-box-landing'>
                            <h3>Free</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.basic.annual : prices.basic.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Ideal for new developers testing basic security on small projects.</p>
                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>3 Audits /Month</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>

                            </div>
                            <Link  to="/login?planId=Free&billingCycle=none" ><button className="plan">Get Started</button></Link>
                        </div>
                        <div className='pricing-box-landing'>
                            <h3>Pro</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.pro.annual : prices.pro.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Suitable for professionals needing regular audits for ongoing projects.</p>
                                <div className="price1">
                                    <div>
                                        <li class="requests-number"><span className="check-icon">✔</span>100 Audits /Month</li>
                                        <li class="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li class="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>
                            </div>
                            <Link to={`/login?planId=Pro&billingCycle=${isAnnual ? 'annual' : 'monthly'}`}><button className="plan">Select Plan</button></Link>
                        </div>
                        <div className='pricing-box-landing' style={{ height: '360px', border: '3px solid rgb(140 16 131)'}}>
                            <h3><img src={Star} id="star"></img>Ultra</h3>
                            <div className="price-f">
                                <div className="price">
                                    <p className="number-price">${isAnnual ? prices.ultra.annual : prices.ultra.monthly}</p>
                                    <p className="mensual-price">/ Month</p>
                                </div>
                                <p style={{ fontSize: '0.9rem'}}>Best for advanced users with multiple projects needing regular security checks.</p>
                                <div className="price1">
                                    <div>
                                        <li className="requests-number"><span className="check-icon">✔</span>300 Audits /Month</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                        <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                    </div>
                                </div>
                            </div>
                            <Link to={`/login?planId=Ultra&billingCycle=${isAnnual ? 'annual' : 'monthly'}`}><button className="cta-free-button" style={{ margin:'auto', padding: '10px 60px' }}>Select Plan</button></Link>
                        </div>
                        <div className='pricing-box-landing'>
                                <h3>Business</h3>
                                <div className="price-f">
                                    <div className="price">
                                        <p className="number-price">${isAnnual ? prices.business.annual : prices.business.monthly}</p>
                                        <p className="mensual-price">/ Month</p>
                                    </div>
                                    <p style={{ fontSize: '0.9rem'}}>Unlimited, customizable audits tailored to your specific project needs.</p>


                                    <div className="price1">
                                        <div>
                                            <li class="requests-number"><span className="check-icon">✔</span>All detectors</li>
                                            <li className="requests-number"><span className="check-icon">✔</span>Unlimited audits</li>
                                            <li className="requests-number"><span className="check-icon">✔</span>Static audit (code analysis)</li>
                                        </div>
                                    </div>
                                </div>
                                <Link to={`/login?planId=Business&billingCycle=${isAnnual ? 'annual' : 'monthly'}`}><button className="cta-free-button" style={{ margin:'auto', padding: '10px 60px' }}>Select Plan</button></Link>
                        </div>
                </div>

            </div>

    </section>
    )
}

export default Pricing;