

import React from 'react';
import { Outlet} from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import '../App.css';

const Layout = () => {

  return (
    <div>
      <Header />
      <div id="body-content">
        <Sidebar />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
