// ChangePhoto.js
import React, { useState, useRef } from 'react';
import axios from 'axios';
import close from '../../images/components/close.svg';

function ChangePhoto({ user, onClose, onSave }) {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [error, setError] = useState('');
    const [isDragOver, setIsDragOver] = useState(false);
    const fileInputRef = useRef(null);
    const urlbackend = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();  // Prevent default behavior (Prevent file from being opened)
        setIsDragOver(true);
    };
    const handleDragLeave = (event) => {
        setIsDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragOver(false);
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            handleFileSelection(files[0]);
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            handleFileSelection(event.target.files[0]);
        }
    };

    const handleFileSelection = (file) => {
        if (!file.type.match(/image\/(jpeg|png)/)) {
            setError('Only JPG and PNG formats are allowed.');
            return;
        }
        if (file.size > 2097152) { // 2MB
            setError('File size must be less than 2MB.');
            return;
        }
        setError('');
        setFile(file);
        setFileName(file.name); 
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        if (!file) {
            setIsLoading(false);
            setError('Please select a file.');
            return;
        }
        const formData = new FormData();
        formData.append('profilePicture', file);
    
        try {
            const response = await axios.post(urlbackend + '/session/user/profile-picture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onSave(response.data); 
            onClose(); 

            setIsLoading(false);
            setTimeout(() => {
                window.location.reload();
              }, 500);
        
           
        } catch (error) {
            setIsLoading(false);
            setError('Failed to upload image.');
        }
    };
    
    return (
        <div className="change-photo-modal">
            <div className="backdrop" onClick={onClose}></div>
            <div className="modal-content">
                <div className="title-close-popup">
                    <button onClick={onClose} style={{ border: '2px solid #c389d194'}}><img src={close}></img></button>
                    <h2 style={{ marginBottom: '10px', marginTop: '-10px'}}>Change Profile Picture</h2>
                    
                </div>
                <div className="drop-area" 
                    onDragOver={handleDragOver} 
                    onDrop={handleDrop}
                    onDragLeave={handleDragLeave}
                    style={{ backgroundColor: isDragOver ? '#f0f0f04d' : 'transparent' }}>
                    <p>Drop file here or <button onClick={() => fileInputRef.current.click()}>browse</button></p>
                    <input 
                        type="file" 
                        ref={fileInputRef} 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }}
                    />
                    {fileName && <p>File: {fileName}</p>}
                    <p style={{ fontSize:'0.8rem'}}>Must be in PNG or JPG format / Max size 2 MB</p>
                </div>
                {error && <p className="error">{error}</p>}

                <button onClick={handleSubmit} id="save-picture">
                        {isLoading ? <div className="loader"></div> : 'Save'}
                </button>
            </div>
        </div>
    );
}

export default ChangePhoto;
