import React from 'react';
import '../styles/LoadingScreen.css'; // Asegúrate de crear este archivo CSS

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-icon">
        {/* Puedes usar un SVG o un ícono de alguna librería como FontAwesome */}
        <svg
          className="spinner"
          width="50"
          height="50"
          viewBox="0 0 50 50"
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>
      </div>
      <div className="loading-text">Loading...</div>
      <div className="loading-bar">
        <div className="loading-progress"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
