import React from "react";
import { Link } from 'react-router-dom';
import '../../styles/components/howitworks.css'
import Screen from '../../images/landingpage/screen.png'
import SmartContract from '../../images/landingpage/smart-contract.svg'


function HowItWorks () {
    return (
        <section id="solution">
            <div className="how-it-works">
                <div className="solution-text">
                    <h4><img src={SmartContract} style={{ width: '50px'}} alt="Solidity"></img>Fast & Simple</h4>
                    <h2>Simplify Your Audit Process</h2>
                    <p>Using QUBOC is straightforward, simply paste your Solidity code into our editor and click 'Audit'. In moments, you'll receive a detailed report highlighting potential vulnerabilities and recommendations for enhancements. Our process is designed to be quick and efficient, providing you with actionable insights without any delay.</p>
                    <Link  to="/login?planId=Free&billingCycle=none" ><button className="cta-free-button" >Try now free</button></Link>
                </div>
                <div className="solution-image">
                    <img src={Screen} id="screen" alt="smart-contract-good-interface"></img>
                </div>

            </div>
        </section>
    )
}

export default HowItWorks