import React, { useState, useEffect, useRef } from 'react';
import '../styles/login.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import google from '../images/google-icon.png';
import eyeOpenIcon from '../images/components/eye-open.svg';
import eyeClosedIcon from '../images/components/eye-closed.svg';
import ReCAPTCHA from "react-google-recaptcha";
const urlbackend = process.env.REACT_APP_BACKEND_URL;
const captchaPublicKey = process.env.RECAPTCHA_PUBLIC_KEY


const Login = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true); 
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const captcha = useRef(null);

  const [loginCredentials, setLoginCredentials] = useState({
    email: '',
    password: '',
    rememberMe: false
});



  const [registerData, setRegisterData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '', // Confirm password could also be used for validation before sending the data
    name: '',
    lastname: '',
    current_plan: 'Free', // Este valor se ajustará dinámicamente
    billingCycle: 'monthly' 
  });

  // Usar useEffect para capturar los parámetros de la URL
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const planId = searchParams.get('planId');
    const billingCycle = searchParams.get('billingCycle');

    if (planId && billingCycle) {
      setRegisterData(prev => ({
        ...prev,
        current_plan: planId,
        billingCycle
      }));
    }
  }, []);
  
  const handleCaptcha = (value) => {
    setCaptchaValue(value);
    if(captcha.current.getValue()){
    }else{
     alert('Please verify you are not a robot.');
    }
  };

  const handleFocus = () => {
    setIsPasswordFocused(true);
  };

  const handleBlur = () => {
    setIsPasswordFocused(false);
  };

  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    oneNumber: false,
    oneUpper: false,
    oneSpecial: false
  });
  useEffect(() => {
    setPasswordValidation({
      minLength: registerData.password.length >= 8,
      oneNumber: /[0-9]/.test(registerData.password),
      oneUpper: /[A-Z]/.test(registerData.password),
      oneSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(registerData.password)
    });
  }, [registerData.password]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const toggleForm = () => {
    setIsLogin(!isLogin); // Cambia el estado entre login y registro inmediatamente
  };

  
  
  const handleRegisterChange = (event) => {
    const { name, value } = event.target;
    setRegisterData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  const handleRegisterSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!captcha) {
      setError("Please verify you are not a robot.");
      setIsLoading(false);
      return;
    }
  
    // Incluye el valor del captcha en los datos de registro
    const dataToSend = {
      ...registerData,
      captcha: captchaValue
    };
  
    try {
      const response = await axios.post(urlbackend + '/register', dataToSend, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true // Esto es necesario para que las cookies se envíen y se reciban.
      });
      if (response.data.url) {
        // Redirigir al checkout de Stripe para los planes pagos
        window.location.href = response.data.url;
      } else {
        // Redirigir directamente al dashboard para el plan gratuito
        window.location.href = '/dashboard';
      }
    } catch (error) {
      setIsLoading(false); 
      setError(error.response ? error.response.data.message : 'Unexpected error occurred');
    } 
  };
  
  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setLoginCredentials(prevCredentials => ({
      ...prevCredentials,
      [name]: value
    }));
  };
  axios.interceptors.request.use(request => {
    // Ver las cookies enviadas con la solicitud
    return request;
  });
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (loginCredentials.rememberMe) {
        localStorage.setItem('rememberedLogin', loginCredentials.email);
      } else {
        localStorage.removeItem('rememberedLogin');
      }
      const response = await axios.post(urlbackend + '/login', {
        email: loginCredentials.email, 
        password: loginCredentials.password,
        rememberMe: loginCredentials.rememberMe
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
      setIsLoading(false);       
      if (response.data.success) {
        window.location.href = '/dashboard';
      } else {
        setError(response.data.error);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.response ? error.response.data.error : 'Unexpected error occurred');
    }
  };
  

  const handleGoogleLogin = (event) => {
    event.preventDefault();
    const { current_plan, billingCycle } = registerData;
    const authUrl = `${process.env.REACT_APP_AUTH_URL}?planId=${current_plan}&billingCycle=${billingCycle}`;
    window.location.href = authUrl;
  };

  const handleRememberMeChange = (e) => {
    setLoginCredentials(prevCredentials => ({
      ...prevCredentials,
      rememberMe: e.target.checked
    }));
  };
  
  useEffect(() => {
    const rememberedLogin = localStorage.getItem('rememberedLogin');
    if (rememberedLogin) {
      setLoginCredentials(prevCredentials => ({
        ...prevCredentials,
        login: rememberedLogin,
        rememberMe: true
      }));
    }
  }, []);
  



  return ( 
    <section id="login-section">
      <div className='login-register-container'>
        <div className={`welcome-div ${isLogin ? 'isLogin' : 'notLogin'}`} style={{ transform: isLogin ? 'translateX(0)' : 'translateX(67%)' }}>
          <h1>{isLogin ? 'Welcome Back!' : 'Hello Friend!'}</h1>
          <p>{isLogin ? 'Sign in to continue accessing your account.' : 'Sign up to start your journey with us.'}</p>
          <button style={{ margin: '0px'}} onClick={toggleForm}>{isLogin ? 'Sign Up' : 'Sign In'}</button>
      </div>
        <div className="login-div"  style={{ transform: isLogin ? 'translateX(0)' : 'translateX(-150%)' }}> 
          {
            isLogin ? (
              <div className="login-form">
                <div className="login-title">
                  <h2>Login</h2>
                </div>
                <form className="login-form-2" onSubmit={handleLoginSubmit}>
                  <div className="inputs">
                    <label>Email</label>
                    <input type="text" name="email" value={loginCredentials.email}  onChange={handleLoginChange} />
                    <label>Password</label>
                    <input 
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={loginCredentials.password} onChange={handleLoginChange}
                       
                      required
                    />
                    <button type="button" onClick={togglePasswordVisibility} className="toggle">
                      <img src={showPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                    </button>
                  </div>
                  {error && <div className="error">{error}</div>}
                  <div className='sessions-sets'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <input 
                      type='checkbox' 
                      id='remember-me' 
                      checked={loginCredentials.rememberMe} 
                      onChange={handleRememberMeChange} 
                      style={{ cursor: 'pointer' }}
                      />
                      <label htmlFor='remember-me' id="remember-label" style={{ marginLeft: '0.5rem', cursor: 'pointer' }}>Remember me</label>
                    </div>

                    <Link to="/forgot-password" target='blank' className="form-p">
                      <p style={{ fontSize: '0.9rem' }}>Forgot password?</p>
                    </Link>

                  </div>

                  <button className="submit" type="submit">{isLoading ? <div className="loader"></div> : 'Log In'}</button>
                  <button className="google-signup" onClick={handleGoogleLogin}>
                    <img src={google} id="google-img" alt="Sign up with Google" />
                    Continue with Google
                  </button>
                  <p className="switch-form" onClick={toggleForm}>Don't have an account? Sign up.</p>
                </form>
              </div>
            ) : (
              <div className="login-form-register">
                <div className="login-title">
                  <h2>Register</h2>
                </div>
                <form className="login-form-2" style={{height: 'auto', marginTop: '0px'}} onSubmit={handleRegisterSubmit}>
                  <div className="inputs-register">
                    <label>Username</label>
                    <input type="text" name="username"  value={registerData.username} onChange={handleRegisterChange} />
                    <label>Email Address</label>
                    <input type="email" name="email" value={registerData.email} onChange={handleRegisterChange} />
                    <label>Password</label>
                    <input 
                      type={showPassword ? 'text' : 'password'}
                      name= 'password'
                      value={registerData.password}
                      onChange={handleRegisterChange}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"      
                      required
                      className={`password-register ${isPasswordFocused ? 'active' : ''}`}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <button type="button" onClick={togglePasswordVisibility} className="register-toggle">
                      <img src={showPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                    </button>
                    <ul className={`password-criteria-register ${isPasswordFocused ? 'active' : ''}`}>
                        <li style={{ color: passwordValidation.minLength ? '#11d03a' : '#b6b6b6' }}>At least 8 characters</li>
                        <li style={{ color: passwordValidation.oneNumber ? '#11d03a' : '#b6b6b6' }}>At least one number</li>
                        <li style={{ color: passwordValidation.oneUpper ? '#11d03a' : '#b6b6b6' }}>At least one uppercase letter</li>
                        <li style={{ color: passwordValidation.oneSpecial ? '#11d03a' : '#b6b6b6' }}>At least one special character</li>
                  </ul>
                    <label>Confirm Password</label>
                    <input 
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword" 
                      value={registerData.confirmPassword}
                      onChange={handleRegisterChange}
                      required
                    />
                    <button type="button" onClick={toggleConfirmPasswordVisibility} className="confirm-password">
                      <img src={showConfirmPassword ? eyeOpenIcon : eyeClosedIcon} alt="Toggle password visibility" />
                    </button>
                  </div>
                  {error && <div className="error2">{error}</div>}
                  <ReCAPTCHA
                    sitekey = '6Leaxk8qAAAAACy--mRsclRH7uC3k4eHc4cu0BWc'
                    onChange={handleCaptcha}
                    ref={captcha}
                    style={{ display: 'flex', justifyContent: 'center', margin:'0 0 20px 0'}}
                  />
                  <button className="submit" type="submit">{isLoading ? <div className="loader"></div> : 'Sign Up'}</button>
                  <button className="google-signup" onClick={handleGoogleLogin}>
                    <img src={google} id="google-img" alt="Register with Google" />
                    Sign Up with Google
                  </button>
                  <p className="switch-form" onClick={toggleForm}>Already have an account? Sign in.</p>
                </form>
              </div>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default Login;