import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/components/termsofservice.css'
import logo from '../../images/logo.svg';
import { BackgroundGradientAnimation } from '../subcomponents/background-gradient-animation'
import Linkedin from '../../images/landingpage/linkedin.svg';
import { Helmet } from 'react-helmet';

function TermsOfService() {

  
    return(
        <BackgroundGradientAnimation>
            <Helmet>
                <title>Terms of Service - QUBOC</title>
                <meta name="description" content="Review the terms and conditions for using the QUBOC platform. Understand your rights and responsibilities as you use our smart contract auditing services." />
                <meta property="og:title" content="Terms of Service - QUBOC"/>
                <meta property="og:description" content="Detailed terms of service for users of the QUBOC smart contract auditing platform."/>
                <meta property="og:url" content="https://www.quboc.io/terms-of-service"/>
                <link rel="canonical" href="https://www.quboc.io/terms-of-service"/>
            </Helmet>
            <section id="terms-of-services" style={{ cursor: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iNDUiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMjAsIDE2LCAxNikiPg0KICAgICAgICA8cGF0aCBkPSJNMTYgMiBRMjAgMTAgMjQgMTggUTI0LjUgMjAgMjQgMjIgUTIwIDIwIDE2IDE4IFExMiAyMCA4IDIyIFE3LjUgMjAgOCAxOCBRMTIgMTAgMTYgMloiIA0KICAgICAgICAgICAgIGZpbGw9IiMzNDNlNzYiIHN0cm9rZT0iI2Q3YjhkOCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCiAgICAgICAgPHBhdGggZD0iTTE2IDQgTDIzIDE4IFEyMi41IDE5IDIwIDE5IEwxNiAxNiBMMTIgMTkgUTkuNSAxOCAxOCAxNiBMMTYgNFoiIA0KICAgICAgICAgICAgIGZpbGw9IiMzNDNlNzYiLz4NCiAgICA8L2c+DQo8L3N2Zz4NCg=='), auto"}}>
                <header>
                    <Link to="/"><img src={logo}></img></Link>
                    <p>QUBOC</p>
                </header>
                <div className='terms-container'>
                    <h1>Terms of Service</h1>
                    <h3>Last Updated: 13/08/2024</h3>
                    <p>Welcome to QUBOC! These terms of service ("Terms") govern your access to and use of QUBOC’s services, so please read them carefully before using our services.</p>
                    <div className='terms' style={{ marginTop: '30px'}}>
                        <h2>1. Acceptance of Terms</h2>
                        <p>By accessing or using our platform, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our services.</p>
                        <h2>2. Services Offered</h2>
                        <p>QUBOC provides automated smart contract auditing services for Solidity contracts. Our services are designed to detect vulnerabilities and provide recommendations for enhancing the security and efficiency of your smart contracts.</p>
                        <h2>3. Account Registration</h2>
                        <p>You must register for an account to use certain features of our services. You agree to provide accurate and complete information and keep your account information updated.</p>
                        <h2>4. Use of Services</h2>
                        <p>You agree to use our services only for lawful purposes and in accordance with these Terms. You will not use our services to infringe the rights of others, including intellectual property rights.</p>
                        <h2>5. Fees and Payments</h2>
                        <p>We offer various subscription plans for our services. Fees for our services are specified on our website and must be paid in advance. We may modify our fees with prior notice.</p>
                        <h2>6. Intellectual Property</h2>
                        <p>All rights, title, and interest in and to our services, including all intellectual property rights, are and will remain the exclusive property of QUBOC and its licensors.</p>
                        <h2>7. User Content</h2>
                        <p>You retain all rights to the smart contracts and other content you submit to our platform. You grant QUBOC a limited license to use, modify, and display the content solely in connection with providing you with our services.</p>
                        <h2>8. Privacy</h2>
                        <p>Our use of your personal information is governed by our Privacy Policy. Please review it to understand our practices.</p>
                        <h2>9. Termination</h2>
                        <p>You may stop using our services at any time. We may suspend or terminate your access to the services if you violate any provision of these Terms.</p>
                        <h2>10. Disclaimers</h2>
                        <p>Our services are provided "as is" and "as available" without any warranties, express or implied. We do not warrant that our services will be uninterrupted, secure, or free from errors.</p>
                        <h2>11. Limitation of Liability</h2>
                        <p>In no event will QUBOC be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to these Terms or the use of our services.</p>
                        <h2>12. Changes to Terms</h2>
                        <p>We reserve the right to modify these Terms at any time. We will provide notice of these changes by revising the date at the top of the Terms and, in some cases, we may provide additional notice.</p>
                        <h2>13. Governing Law</h2>
                        <p>These Terms are governed by the laws of the United States, without regard to its conflict of law principles.</p>
                        <h2>14. Contact Information</h2>
                        <p>If you have any questions about these Terms, please contact us at support@quboc.io.</p>
                        <Link to="/"><button style={{ margin: '0 0 0 87%', padding: '10px 30px' }}>Back to Home</button></Link>

                    </div>
                </div>
                <footer>
                    <div className='footer-container'>
                        <div className='website-footer'>
                            <div className='logo-footer'>
                                <img src={logo} style={{ width: '40px'}} alt="quboc logo" />
                                <p style={{ margin: '0px', marginLeft: '10px', fontWeight: '700', fontSize: '2rem', color: 'white'}}>QUBOC</p>
                            </div>
                            <p style={{ fontWeight: '400', fontSize: '0.8rem', color: '#c4c4c4'}}>Securing smart contracts, empowering developers.</p>
                            <p style={{ fontWeight: '400', fontSize: '0.8rem', color: '#c4c4c4'}}>Copyright © 2024 - All rights reserved</p>
                        </div>
                        <div className='website-footer'>
                            <p>WEBSITE</p>
                            <div style={{lineHeight: '2rem'}}>
                                <a className='footer-a' href="/#about" target='blank'><li>About</li></a>
                                <a className='footer-a' href="/#solution" target='blank'><li>How it works</li></a>
                                <a className='footer-a' href='/#pricing' target='blank'><li>Pricing</li></a>
                                <a className='footer-a' href='/#faqs' target='blank'><li>FAQS</li></a>
                            </div>
                        </div>
                        <div className='website-footer'>
                            <div className='website-footer2'>
                                <p>LEGAL</p>
                                <div style={{lineHeight: '2rem'}}>
                                    <a className='footer-a' href="/terms-of-service"><li>Terms of services</li></a>
                                    <a className='footer-a' href="/privacy-policy"><li>Privacy policy</li></a>
                                </div>
                            </div>
                            <div className='website-footer2'>
                                <p>MEDIA</p>
                                <div style={{lineHeight: '2rem'}}>
                                    <Link to="/" ><img src={Linkedin} style={{ width: '25px'}}></img></Link>
                                </div>
                            </div>
                        </div>
                        <div className='website-footer'>
                                <p>CONTACT SUPPORT</p>
                                <p style={{ color: 'white', fontWeight: '400'}}>support@quboc.io</p>
                        </div>

                    </div>
                </footer>

            </section>

        </BackgroundGradientAnimation>

    )
}

export default TermsOfService;
