import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, extend, useThree }from '@react-three/fiber';
import { BufferGeometry, Float32BufferAttribute,  LineBasicMaterial, LineSegments, EdgesGeometry, DoubleSide} from 'three';
// Extiende react-three-fiber con geometrías personalizadas
extend({ BufferGeometry });


const SolidityIcon3D = () => {
    const groupRef = useRef();
    const depth = 1; // Profundidad para la extrusión

    useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.y += 0.005;
        }
    });
  
    // Función para crear un prisma triangular con todas sus caras
    const createTrianglePrism = (vertices, color, edgeColor) => {
      const frontVertices = vertices;
      const backVertices = vertices.map(v => [v[0], v[1], v[2] - depth]); // Desplaza en z para la cara trasera
  
      // Conectar las bases frontal y trasera con caras laterales
      const faces = [
        ...frontVertices, // Base frontal
        ...backVertices,  // Base trasera
        frontVertices[0], backVertices[0], backVertices[1], frontVertices[1], // Lado 1
        frontVertices[1], backVertices[1], backVertices[2], frontVertices[2], // Lado 2
        frontVertices[2], backVertices[2], backVertices[0], frontVertices[0], // Lado 3
      ].flat();

      const indices = [
        0, 1, 2,  3, 4, 5, // Bases
        6, 7, 9,  7, 8, 9, // Lado 1
        10, 11, 13,  11, 12, 13, // Lado 2
        14, 15, 17,  15, 16, 17  // Lado 3
      ];
  
      const geometry = new BufferGeometry();
      geometry.setIndex(indices);
      geometry.setAttribute('position', new Float32BufferAttribute(faces.flat(), 3));

      const edges = new EdgesGeometry(geometry);
      
    return (
        <>
          <mesh geometry={geometry}>
            <meshPhongMaterial attach="material" color={color} side={DoubleSide} specular="#FFFFFF" shininess={100} />
          </mesh>
          <lineSegments geometry={edges}>
            <lineBasicMaterial attach="material" color={edgeColor} linewidth={2} />
          </lineSegments>
        </>
      );
    };
  
    const verticesTop = [
      [[0, 1, 0], [1, 2.5, 0], [2, 1, 0]],     // Triángulo superior derecho
      [[-1, 2.5, 0], [0, 1, 0], [1, 2.5, 0]],  // Triángulo medio superior (inverso)
      [[-2, 1, 0], [-1, 2.5, 0], [0, 1, 0]],   // Triángulo superior izquierdo
      [[-2, 1, 0], [-1, -0.5, 0], [0, 1, 0]]   // Triángulo medio inferior (inverso)
    ];
  
    const verticesBottom = [
      [[-2, -1, 0], [-1, -2.5, 0], [0, -1, 0]],  // Triángulo inferior izquierdo
      [[-1, -2.5, 0], [0, -1, 0], [1, -2.5, 0]], // Triángulo medio inferior (inverso)
      [[0, -1, 0], [1, -2.5, 0], [2, -1, 0]],    // Triángulo inferior derecho
      [[0, -1, 0], [1, 0.5, 0], [2, -1, 0]]      // Triángulo medio superior (inverso)
    ];
  
    return (
      <group ref={groupRef} position={[0, 0, 0]}>
        {verticesTop.map((vertices, index) => createTrianglePrism(vertices, `hsl(270, 50%, ${35 + index * 5}%)`, '#564780'))}
        {verticesBottom.map((vertices, index) => createTrianglePrism(vertices, `hsl(270, 50%, ${35 + index * 5}%)`, '#564780'))}

      </group>
    );
  };


export default SolidityIcon3D;