// CustomWaveBackground.js
import React from 'react';

const CustomWaveBackground = ({ children }) => {
  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      paddingBottom: window.innerWidth <= 380 ? '50px' : '0px'
    }}>
      <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: '-100' }} viewBox="0 0 100 100" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-100 0C-22.365 -1.595 -40 -9.5 -23 -10.98C-18 -12 -17.845 -13.755 -16.37 -16.37C-14.895 -18.98 -12.24 -21.22 -9.315 -22.485C-6.39 -23.75 -3.195 -24.045 0 -35.335L0 0Z"
            fill="url(#grad1)"
            transform="translate(100 100) scale(1.5)" />  
        <path d="M24.335 0C23.01 1.785 21.685 5.57 20.695 8.57C19.705 11.575 19.06 14.79 17.21 17.21C15.36 19.63 12.31 21.255 9.24 22.31C6.17 23.365 3.085 23.85 0 35.335L0 0Z"
            fill="url(#grad1)"
            transform="translate(0 0) scale(1.5)" /> 
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#b23997', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#59377f', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
      <div style={{ position: 'relative', background: 'radial-gradient(circle at 50% 50%, #23192c 0%, transparent 50%);' }}>
        {children}
      </div>
    </div>
  );
};

export default CustomWaveBackground;

