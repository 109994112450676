import React, { useEffect, useRef, useState } from 'react';
import '../../styles/components/background-gradient-animation.css'

export const BackgroundGradientAnimation = ({
  gradientBackgroundStart = "rgb(108, 0, 162)",
  gradientBackgroundEnd = "rgb(0, 17, 82)",
  firstColor = "rgb(18 113 255 / 22%)",
  secondColor = "221, 74, 255",
  thirdColor = "100, 220, 255",
  fourthColor = "200, 50, 50",
  fifthColor = "190, 69, 169",
  pointerColor = "77, 68, 103",
  size = "40%",
  blendingValue = "hard-light",
  children,
  className,
  interactive = true,
  containerClassName,
}) => {
  const interactiveRef = useRef(null);

  const [curX, setCurX] = useState(0);
  const [curY, setCurY] = useState(0);
  const [tgX, setTgX] = useState(0);
  const [tgY, setTgY] = useState(0);

  useEffect(() => {
    document.documentElement.style.setProperty('--gradient-background-start', gradientBackgroundStart);
    document.documentElement.style.setProperty('--gradient-background-end', gradientBackgroundEnd);
    document.documentElement.style.setProperty('--first-color', firstColor);
    document.documentElement.style.setProperty('--second-color', secondColor);
    document.documentElement.style.setProperty('--third-color', thirdColor);
    document.documentElement.style.setProperty('--fourth-color', fourthColor);
    document.documentElement.style.setProperty('--fifth-color', fifthColor);
    document.documentElement.style.setProperty('--pointer-color', pointerColor);
    document.documentElement.style.setProperty('--size', size);
    document.documentElement.style.setProperty('--blending-value', blendingValue);
  }, [gradientBackgroundStart, gradientBackgroundEnd, firstColor, secondColor, thirdColor, fourthColor, fifthColor, pointerColor, size, blendingValue]);

  useEffect(() => {
    const move = () => {
      if (!interactiveRef.current) {
        return;
      }
      setCurX((prev) => prev + (tgX - prev) / 20);
      setCurY((prev) => prev + (tgY - prev) / 20);
      interactiveRef.current.style.transform = `translate(${Math.round(curX)}px, ${Math.round(curY)}px)`;
    };

    move();
  }, [tgX, tgY]);

  const handleMouseMove = (event) => {
    if (interactiveRef.current) {
      const rect = interactiveRef.current.getBoundingClientRect();
      setTgX(event.clientX - rect.left);
      setTgY(event.clientY - rect.top);
    }
  };

  return (
    <div
      className={`background-gradient-animation-container ${containerClassName}`}
      onMouseMove={interactive ? handleMouseMove : null}
    >
      <div className={`gradients-container ${className}`}>
        <div className="gradient-circle first"></div>
        <div className="gradient-circle second"></div>
        <div className="gradient-circle third"></div>
        <div className="gradient-circle fourth"></div>
        <div className="gradient-circle fifth"></div>
        {interactive && <div ref={interactiveRef} className="pointer-circle"></div>}
      </div>
      {children}
    </div>
  );
};
