import React, { useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import * as monaco from 'monaco-editor';

const MonacoEditor = forwardRef((props, ref) => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Definir el tema personalizado
    monaco.editor.defineTheme('custom-dark', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        { token: '', foreground: '73e2b7' },
        { token: 'keyword', foreground: '7d9eff' },
        { token: 'number', foreground: 'b5cea8' },
        { token: 'string', foreground: 'ce9178' },
        { token: 'variable', foreground: '9cdcfe' },
        { token: 'type', foreground: '4ec9b0' }
      ],
      colors: {
        'editor.background': '#1e1e1e'
      }
    });

    // Crear el editor y aplicar el tema personalizado
    const editor = monaco.editor.create(containerRef.current, {
      value: '// Insert your Solidity code here\n',
      language: 'sol',
      theme: 'custom-dark', // Aplicar el tema aquí
    });
    editorRef.current = editor;

    return () => editor.dispose();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => editorRef.current.getValue(),
  }));

  return <div ref={containerRef} style={{ height: '700px', width: '100%' }} />;
});

export default MonacoEditor;

