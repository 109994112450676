import React from 'react';
import close from '../../images/components/close.svg';

function ConfirmPlanChangePopup({ onClose, onConfirm, isLoading, plan, cycle, error, successMessage }) {
    return (
        <div className="backdrop">
            <div className="modal-content">
                <div className="title-close-popup">
                    <button  onClick={onClose} style={{ border: '2px solid #c389d194'}}><img src={close}></img></button>
                    <h2>Confirm Plan Change</h2>
                </div>
                {!successMessage && !error && (
                    <p>Are you sure you want to change your subscription to the {plan} plan? Your payment method on file will be used. To change your payment method, please visit the billing portal.</p>
                )}
                
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {successMessage && <p style={{ color: 'rgb(17, 208, 58)' }}>{successMessage}</p>}
                <div style={{ display: 'flex', justifyContent: 'space-around', padding: '20px 0px' }}>
                    <button style={{ padding: '10px 30px'}} onClick={() => onConfirm(plan, cycle)} disabled={isLoading}>
                        {isLoading ? <div className="loader"></div> : 'Upgrade'}
                    </button>
                    <button id="CancelChangePlan" onClick={onClose} style={{ backgroundColor: '#950101', padding: '10px 30px'}}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmPlanChangePopup;
