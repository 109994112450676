import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import '../styles/usage.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend  } from 'chart.js';
import { useUser } from '../contexts/UserContext'; 

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart({ auditData }) {
  const options = {
      scales: {
          y: {
              beginAtZero: true,
              ticks: {
                  color: "#F5F5F5", 
                  font: {
                      family: 'Montserrat', 
                  }
              },
              title: {
                  display: true,
                  text: 'Number of Audits',
                  color: "#F5F5F5",
                  font: {
                      family: 'Montserrat', 
                  }
              },
              grid: {
                  display: false 
              },
              border: {
                color: '#7a649e',
                width: 3
              }
          },
          x: {
              ticks: {
                  color: "#F5F5F5", 
                  font: {
                      family: 'Montserrat', 
                  }
              },
              title: {
                  display: true,
                  text: 'Date',
                  color: "#F5F5F5",
                  font: {
                      family: 'Montserrat',
                  }
              },
              grid: {
                  display: false 
              },
              border: {
                color: '#7a649e',
                width: 3
              }
          },
      },
      plugins: {
          legend: { display: false },
          title: {
              display: true,
              text: 'Daily Audits',
              color: "#F5F5F5",
              font: {
                  size: 16,
                  family: 'Montserrat', 
              }
          },
      },
      elements: {
          bar: {
              borderRadius: 7, 
          }
      }
  };

  return <div className="graph1"><Bar data={auditData} options={options} /></div>;
}




  function Usage() {
    const { user } = useUser();
    const urlbackendNode = process.env.REACT_APP_BACKEND_URL;
    const [userData, setUserData] = useState({
      totalAudits: 0,
      plan: 'Free',
      auditsLeft: 10
    });
    const [rawData, setRawData] = useState({ labels: [], data: [] });
    const options = [
        { value: '7d', label: 'Last 7 days' },
        { value: '30d', label: 'Last 30 days' }
    ];
    const [selectedOption, setSelectedOption] = useState(options[1]);

    const auditData = useMemo(() => {
        const sliceIndex = selectedOption.value === '7d' ? Math.max(rawData.labels.length - 7, 0) : 0;
        return {
            labels: rawData.labels.slice(sliceIndex),
            datasets: [{
                label: 'Number of Audits',
                data: rawData.data.slice(sliceIndex),
                backgroundColor: 'rgba(52, 92, 156, 0.57)',
                borderColor: 'rgba(52, 92, 156, 0.57)',
                borderWidth: 1,
            }]
        };
    }, [rawData, selectedOption]);

  const customStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      color: '#dfdfdf',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      borderColor: '#345c9c91',
      borderWidth: '2px',
      borderStyle: 'solid',
      boxShadow: 'none',
      fontSize: '0.9rem', 
      '&:hover': {
          borderColor: '#345c9c91'
      },
      height: '40px',
      display: 'flex',
      alignItems: 'center',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? '#433f74' : 'transparent',
      color: isSelected ? 'white' : 'dfdfdf',
      cursor: 'pointer',
      fontSize: '0.9rem',
      '&:active': {
        backgroundColor: isSelected ? '#433f74' : '#101631',
      },
      '&:hover': {
        backgroundColor: '#433f74'
      },
      
    }),
    ValueContainer: styles => ({
      ...styles,
        height: '100%',
    }),
    IndicatorsContainer: styles => ({
      ...styles,
        height: '100%',
    }),
    menu: styles => ({
      ...styles,
        backgroundColor: '#0000005e', 
        boxShadow: 'none'
    }),
    placeholder: styles => ({
      ...styles,
      color: 'grey'
    }),
    singleValue: styles => ({
      ...styles,
      color: '#dfdfdf'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
  };

  useEffect(() => {
    if (user && user._id) {
        async function fetchData() {
            const userDetailsResponse = await axios.get(urlbackendNode + `/user/details/${user._id}`);
            console.log("Get realizado a ", userDetailsResponse)
            const userAuditHistoryResponse = await axios.get(urlbackendNode + `/audit/history/${user._id}`);

            setUserData({
                totalAudits: userDetailsResponse.data.totalAudits,
                plan: userDetailsResponse.data.current_plan,
                auditsLeft: userDetailsResponse.data.auditsLeft
            });

            setRawData({
                labels: userAuditHistoryResponse.data.labels,
                data: userAuditHistoryResponse.data.data
            });
        } 

        fetchData();
    }
}, [user]); // Include selectedOption in the dependency array

  
   
    return (
        <section id="usage">
            <div className="elements">
                <div className="total-audits">
                   <h2>Total Audits</h2>
                   <p>{userData.totalAudits}</p>

                </div>
                <div className="current-plan">
                  <h2>Current Plan</h2>
                  <p>{userData.plan}</p>
                  {user && user.userType !== 'Business' && (
                    <Link to="/dashboard/plans-and-billings"><button>Change Plan</button></Link>
                  )}
                </div>
                <div className="audits-left">
                  <h2>Audits Left</h2>
                  <p>{userData.auditsLeft}</p>

                </div>


            </div>
            <div className="date-selector">
                <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  styles={customStyles}
                  className="date-select"
                />

            </div>
            <div className="graph">
              <BarChart auditData={auditData} />

            </div>

        </section>
    )

}

export default Usage;